import React, { useState, useRef, useLayoutEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  SvgIcon,
  Typography,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const AddTechnology = ({ onClose, technology, selectedsoftware, onAddTechnology }) => {
  const [selectedSoftware, setSelectedSoftware] = useState(selectedsoftware || []);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');

  const focusRef = useRef(null);

  useLayoutEffect(() => {
    focusRef.current?.focus();
  }, [])
  
  const handleDoneClick = () => {
    if (selectedSoftware.length >12) {
      setError('Cannot add more than 12 competencies.');
    } else {
      onAddTechnology(selectedSoftware);
      onClose();
    }
  };

  const handleCheckboxChange = (event) => {
    const technology = event.target.name;
    if (event.target.checked) {
      if (selectedSoftware.length >= 12) {
        setError('Cannot add more than 12 competencies.');
        return;
      }
      setSelectedSoftware((prevSelectedSkills) => [...prevSelectedSkills, technology]);
    } else {
      setSelectedSoftware((prevSelectedSkills) => prevSelectedSkills.filter((skill) => skill !== technology));
      setError('');
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTechnologies = technology.filter((skill) =>
    skill.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Card className="add-skills-dialog" variant="outlined">
      <CardContent>
        <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onClose}>
          <SvgIcon sx={{ width: '20px', height: '20px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_456_20993)">
                <path
                  d="M7 7L17 17M17 7L7 17L17 7Z"
                  stroke="#111827"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_456_20993">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </SvgIcon>
        </IconButton>

        <Box mb={2.5}>
          <Typography className="title" component="h6">
          Add technical competencies
          </Typography>
          <Typography className="sub-text" component="p">
          Please select from list below
          </Typography>
        </Box>

        {error && (
          <Box mb={1.5}>
            <Typography className="error-text" component="p" style={{ color: 'red' }}>
              {error}
            </Typography>
          </Box>
        )}

        <Box mb={1.5}>
          <FormControl fullWidth>
            <TextField
              label="Competency name"
              inputRef={focusRef}
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
            />
          </FormControl>
        </Box>

        <Box className="add-skills-dialog-content" sx={{paddingBottom: '20rem !important'}} mb={3.5}>
          <Box className="content-inner">
            <FormGroup>
              {filteredTechnologies.map((label, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedSoftware.includes(label)}
                      onChange={handleCheckboxChange}
                      name={label}
                    />
                  }
                  key={index}
                  label={label}
                />
              ))}
            </FormGroup>
            
          </Box>
        </Box>

        <Box className="skills-dialog-actions">
          <Stack direction="row" alignItems={'center'} spacing={3}>
            <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onClose} tabIndex={-1}>
              Cancel
            </Button>
            <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleDoneClick}>
              Done
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AddTechnology;
