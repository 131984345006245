import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Dialog,
  Box,
  Card,
  CardContent,
  Link,
  Chip,
  FormControl,
  Grid,
  IconButton,
  SvgIcon,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TypeAhead from 'components/TypeAhead';
import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { changeNameFormat } from 'utils/Utils';
import { CandidateEducationSchema } from 'models/CandidateProfileSchema';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { updateCandidateCertificate } from 'api/users';
import dayjs from 'dayjs';
import { endpoints } from 'utils/axios';
import RemoveCertificate from './RemoveCertificateDialog';
import AddIcon from '@mui/icons-material/Add';
import AddSkills from './AddSkills';
import { deleteCertificateHistory } from 'api/users';
import Overlapping from './Overlapping';

const CertificationDetails = ({ certificateData, onCancel, id, onCertificateDataChange, certificateInstituteData, certificateTitleData, checkingOverlapping }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const isEditMode = !!id;
  const { user } = useAuth();
  const [showFlag, setShowFlag] = useState('');
  const [selectedChips, setSelectedChips] = useState(certificateData?.skills || []);
  const [educationFrom, setEducationFrom] = useState(dayjs(certificateData?.education_from) || null);
  const [educationTo, setEducationTo] = useState(dayjs(certificateData?.education_to) || null);
  const focusRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [addMoreSkills, setAddMoreSkills] = useState(certificateData?.all_skills || []);
  const [chipData, setChipData] = useState(certificateData?.skills?.length > 0 ? certificateData?.skills : certificateData?.all_skills || []);
  const defaultValues = {
    education_from: null,
    education_to: null,
    education_institute_name: '',
    certificate_received: '',
    learning_format: 'Self Study',
    field_of_study: '',
    ...certificateData,
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(CandidateEducationSchema('certificate')),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    watch,
    trigger,
  } = methods;

  const watchFileds = watch();

  useLayoutEffect(() => {
    if (!isEditMode) focusRef.current?.focus()
  }, []);

  const [isChangeSkills, setIsChangeSkills] = useState(false);
  const [openOverlapping, setOpenOverlapping] = useState(false);
  const [type, setType] = useState('');
  const [degree, setDegree] = useState('');
  const [newCertificationData, setNewCertificationData] = useState(null);

  useEffect(() => {
    if ((isDirty || isChangeSkills) && !isEditMode) {
      const formData = getValues();
      formData.skills = selectedChips;
      formData.all_skills = addMoreSkills;
      localStorage.setItem('certificate', JSON.stringify(formData));
      if (checkLocalDataIsEmpty(formData)) localStorage.removeItem('certificate');
      setIsChangeSkills(false);
    }
  }, [watchFileds, isDirty, isChangeSkills]);

  const checkLocalDataIsEmpty = (data) => {
    if (data) {
      return Object.values(data)?.every(
        (value) =>
          value === null ||
          value === undefined ||
          (typeof value === 'string' && value.trim() === '') ||
          (Array.isArray(value) && value.length === 0)
      );
    }
  };

  const handleEducationFromChange = async (date) => {
    if (date) {
      const firstDayOfMonth = date.startOf('month');
      setEducationFrom(firstDayOfMonth);
      setValue('education_from', firstDayOfMonth?.$d);
      trigger('education_from');
    }
  };  

  const handleEducationtoChange = (date) => {
    if (date) {
      const lastDayOfMonth = date.endOf('month');
      setEducationTo(lastDayOfMonth);
      setValue('education_to', lastDayOfMonth?.$d);
      trigger('education_to');
    }
  };

  const skills = [{ label: 'Tax' }, { label: 'Accounting' }, { label: 'Audit' }];

  const format = [{ label: 'Full time - offline' }, { label: 'Full time - online' }, { label: 'Part time - offline' }, { label: 'Part time - online' }, { label: 'Self Study' }];

  const onSubmit = handleSubmit(async (formData) => {
    // console.log(formData);
    if (isEditMode) {
      formData.certificationId = id;
    }
    if (!validateSkills(selectedChips)) {
      return;
    } else {
      setShowFlag('');
    }
    formData.skills = selectedChips;
    formData.all_skills = chipData;
    const {isOverlapping, type, degree} = checkingOverlapping(formData)
    // console.log(isOverlapping, type, degree);
    if (isOverlapping) { 
      setNewCertificationData(formData);
      setDegree(degree);
      setType(type);
      setOpenOverlapping(true);
      return;
    }
    await updateCertification(formData);
  });

  const updateCertification = async (data) => {
    // console.log(data);
    if (data){
    const response = await updateCandidateCertificate(data);
    if (response?.success === true && response.data) {
      if (response?.removedWorkGapResult)
        enqueueSnackbar("Career break has been removed.", { variant: 'success' });
      !isEditMode && localStorage.removeItem('certificate');
      onCertificateDataChange(true);
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }}
  };

  const handleClick = (label) => {
    setIsChangeSkills(true);
    let totalSkillls;
    if (selectedChips.includes(label)) {
      totalSkillls = selectedChips.filter((chip) => chip !== label);
      setSelectedChips(totalSkillls);
    } else {
      if (selectedChips.length >= 8) {
        setShowFlag('You can select up to 8 skills.');
        return;
      } else {
        totalSkillls = [...selectedChips, label];
        setSelectedChips(totalSkillls);
        setShowFlag('');
      }
    }
    validateSkills(totalSkillls);
  };

  const validateSkills = (totalSkillls) => {
    if (totalSkillls?.length < 3) {
      setShowFlag('Please select at least 3 skills.');
      return false;
    } else if (totalSkillls?.length > 8) {
      setShowFlag('You can select up to 8 skills.');
      return false;
    }
    setShowFlag('');
    return true;
  };

  const handleOpenOtpDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRemove(false);
  };

  const handleAddMoreSkills = (moreSkills) => {    
    validateSkills(moreSkills);
    if (moreSkills?.length > 0) {
      setIsChangeSkills(true);
      setSelectedChips(moreSkills);
      setChipData(moreSkills);
    }
  };

  const handleDeleteCertificate = async (certificateId, title, event) => {
    event.stopPropagation();
    if (!certificateId) {
      localStorage.removeItem('certificate');
      onCancel();
    } else {
      setOpenRemove(true);
    }
  };
  const handleDeleteAction = async () => {
    const response = await deleteCertificateHistory({ certificateId: certificateData._id });
    if (response && response?.success) {
      onCertificateDataChange(true);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
    handleClose();
  };
  const handleCloseOverlapping = () => {
    setOpenOverlapping(false);
  };

  if (!certificateInstituteData || !certificateInstituteData?.length > 0 || !certificateTitleData || !certificateTitleData?.length) {
    return (
      <div key="add_edit_edu">
        <Box className="candidate-basic-det-wrapper"></Box>
        <Card className="form-wrapper" variant="outlined">
          <CardContent>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mb={3}>
              <CircularProgress />
            </Stack>
          </CardContent>
        </Card>
      </div>
    );
  }
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Box>
        <Card className="form-wrapper" variant="outlined">
          <CardContent>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mb={3}>
              <Typography variant="h6" >
                {watch('certificate_received') ? changeNameFormat(`${watch('certificate_received')} `) + `at ` : ''}
                {watch('education_institute_name') ? changeNameFormat(watch('education_institute_name')) : 'Certification Details'}
              </Typography>
              <Stack direction="row" alignItems={'center'} spacing={2}>
                {certificateData && (
                  <IconButton
                    className="delete-sec-btn"
                    aria-label="delete"
                    onClick={(event) =>
                      handleDeleteCertificate(certificateData?._id, certificateData?.degree_received, event)
                    }
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z"
                        fill="#FF4949"
                      />
                    </svg>
                  </IconButton>
                )}
                <KeyboardArrowUpOutlinedIcon
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Stack>
            </Stack>
            <Grid container spacing={2} mb={3}>
              <Grid item xs={12} md={6}>
              {/* institute name */}
                <FormControl fullWidth size="small">
                  <Controller
                    control={control}
                    name="education_institute_name"
                    defaultValue={[defaultValues?.education_institute_name || '']}
                    render={({ field }) => (
                      <TypeAhead
                        {...field}
                        {...register('education_institute_name')}
                        ref={focusRef}
                        data={certificateInstituteData}
                        multiple={false}
                        fullWidth
                        label="Institution"
                        placeholder="Institution Name"
                        freeSolo={true}
                        onInputChange={(e, value) => {
                          setValue("education_institute_name", changeNameFormat(value), { shouldDirty: true });
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* certificate received */}
                <FormControl fullWidth size="small">
                  <Controller
                    control={control}
                    name="certificate_received"
                    defaultValue={defaultValues?.certificate_received || ''}
                    render={({ field }) => (
                      <TypeAhead
                        {...field}
                        {...register('certificate_received')}
                        data={certificateTitleData}
                        label="Certification Received"
                        displayTextKey="name"  
                        freeSolo={true} 
                        onInputChange={(e, value) => {                          
                          setValue("certificate_received", changeNameFormat(value), { shouldDirty: true });
                        }}                    
                        onChange={(e, value) => {
                          setValue('all_skills', value?.skills);
                          setChipData(value?.skills);
                          setAddMoreSkills(value?.skills);
                          setSelectedChips([]);
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Specialization */}
                <FormControl fullWidth size="small">
                  <Controller
                    control={control}
                    name="field_of_study"
                    defaultValue={defaultValues?.field_of_study || []}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        {...register('field_of_study')}
                        // displayTextKey="name"
                        label="Specialization"
                        variant="outlined"
                        fullWidth
                        error={!!errors.field_of_study}
                        helperText={errors.field_of_study ? errors.field_of_study.message : ''}
                      onChange={(e) =>
                        setValue("field_of_study", changeNameFormat(e.target.value), { shouldDirty: true })
                      }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* learning format */}
                <FormControl fullWidth size="small">
                  <InputLabel id="learning_format">Learning Format</InputLabel>
                  <Controller
                    control={control}
                    name="learning_format"
                    defaultValue={defaultValues?.learning_format || ''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="learning_format"
                        error={!!errors.learning_format}
                        helperText={errors.learning_format?.message}
                        label="Learning Format"
                        variant="outlined"
                        fullWidth
                      >
                        {format?.map((item) =>
                          <MenuItem value={item?.label}>{item?.label}</MenuItem>
                        )}
                      </Select>
                    )}
                  />
                  <Typography variant="caption" color="error">
                    {errors.learning_format?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* start date */}
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="education_from"
                    defaultValue={defaultValues?.education_from}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        value={dayjs(field?.value)}
                        format="MM/YYYY"
                        views={['year', 'month']}
                        label="Start Date"
                        disableFuture
                        placeholderText="Start Date"
                        onChange={(date) => handleEducationFromChange(date)}
                        closeOnSelect={true}
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!errors?.education_from,
                            helperText: errors?.education_from?.message,
                            // InputProps: {
                            //   readOnly: true,
                            //   onClick: (event) => event.target.blur(),
                            // },
                            // inputProps: {
                            //   tabIndex: -1,
                            // },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* end date */}
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="education_to"
                    defaultValue={defaultValues?.education_to}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        value={dayjs(field?.value)}
                        autoOk={true}
                        format="MM/YYYY"
                        views={['year', 'month']}
                        label="End Date"
                        onChange={(date) => handleEducationtoChange(date)}
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!errors?.education_to,
                            helperText: errors?.education_to?.message,
                            // InputProps: {
                            //   readOnly: true,
                            //   onClick: (event) => event.target.blur(),
                            // },                            
                            // inputProps: {
                            //   tabIndex: -1,
                            // },
                          },
                        }}
                        disableFuture
                        // minDate={educationFrom}
                        closeOnSelect={true}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box className="besic-det-skills-wrapper">
              <Box mb={2}>
                <Typography className="title" variant="body2" gutterBottom>
                  Skills
                </Typography>
                <Typography className="info-txt" variant="body2" gutterBottom>
                  3 to 8 skills can be selected
                </Typography>
              </Box>
              <Stack direction="row" flexWrap="wrap" >
                {chipData?.map((label, index) => (
                  <Chip
                    key={index}
                    label={label}
                    size="small"
                    clickable
                    variant={selectedChips.includes(label) ? 'filled' : 'outlined'}
                    style={{
                      color: selectedChips.includes(label) ? 'white' : 'black',
                      backgroundColor: selectedChips.includes(label) ? '#ade9ab' : 'transparent',
                      border: selectedChips.includes(label) ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
                    }}
                    onClick={() => handleClick(label)}
                    tabIndex={-1}
                  />
                ))}
              </Stack>
              {showFlag && (
                <Typography style={{ color: 'red', fontSize: 'small' }}>
                  {showFlag}
                </Typography>
              )}
              <Link size="small" className="add-more-skills-link" underline="none" onClick={handleOpenOtpDialog} tabIndex={0}
               onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleOpenOtpDialog();
                }
              }}
              >
                Add More Skills
              </Link>
            </Box>
            <Box mt={3}>
              <Stack direction="row" spacing={2}>
                <Button className="cancel-btn" size="large" onClick={onCancel} tabIndex={-1}>
                  Cancel
                </Button>
                <Button className="done-btn" variant="contained" disabled={isSubmitting} type="submit" size="large">
                  {isEditMode ? (isSubmitting ? 'Updating...' : 'Update') : (isSubmitting ? 'Saving...' : 'Done')}
                </Button>
              </Stack>
            </Box>
          </CardContent>
        </Card>
        <Dialog
          open={openRemove}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="otp-dialog-wrapper"
        >
          <RemoveCertificate
            onClose={handleClose}
            onRemove={handleDeleteAction}
            title={certificateData?.certificate_received}
          />
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="otp-dialog-wrapper"
        >
          <AddSkills
            onClose={handleClose}
            skills={addMoreSkills}
            selectedskills={selectedChips}
            onAddSkills={handleAddMoreSkills}
          />
        </Dialog>
        <Dialog
        open={openOverlapping}
        onClose={handleCloseOverlapping}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper"
      >
        <Overlapping onClose={() => handleCloseOverlapping()} 
        onConfirm={() => updateCertification(newCertificationData)} 
        type={type}
        degree={degree} />
      </Dialog>
      </Box>      
    </FormProvider>
  );
};

export default CertificationDetails;
