import { Avatar, Box, Chip, Stack, SvgIcon } from '@mui/material';

const JobNotMatchingIcon = `${process.env.REACT_APP_HOMEPAGE}assets/job-not-matching-icon.png`;
const ModSuitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/moderately-suitable-icon.png`;
const suitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/highly-suitable-icon.png`;

export const CheckIcon = () => (
    <Stack>
        <Box className="match-icon">
            <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                        fill="white"
                    />
                </svg>
            </SvgIcon>
        </Box>
    </Stack>
);
export const CrossIcon = () => (
    <Stack>
        <Box className="error-match-icon">
            <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                    <path
                        d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                        fill="white"
                    />
                </svg>
            </SvgIcon>
        </Box>
    </Stack>
);
export const WarningIcon = () => (
    <Stack>
        <Box className="warning-icon">
            <SvgIcon>
                <svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.46307 0.272727L2.35227 6.49006H1.17188L1.06108 0.272727H2.46307ZM1.7642 9.08097C1.52841 9.08097 1.3267 8.99858 1.15909 8.83381C0.991477 8.66619 0.909091 8.46449 0.911932 8.22869C0.909091 7.99574 0.991477 7.79687 1.15909 7.6321C1.3267 7.46449 1.52841 7.38068 1.7642 7.38068C1.99432 7.38068 2.19318 7.46449 2.3608 7.6321C2.52841 7.79687 2.61364 7.99574 2.61648 8.22869C2.61364 8.38494 2.57244 8.52841 2.4929 8.65909C2.41619 8.78693 2.31392 8.8892 2.18608 8.96591C2.05824 9.04261 1.91761 9.08097 1.7642 9.08097Z" fill="white" />
                </svg>

            </SvgIcon>
        </Box>
    </Stack>
);
export const SuitableChip = () => (
    <Chip
        className="chip-job-matching chip-suitable"
        avatar={<Avatar alt="Natacha" src={suitableIcon} />}
        label="Highly Suitable Job For You"
        size="small"
    />
);
export const ModerateChip = () => (
    <Chip
        className="chip-job-matching chip-mod-suitable"
        avatar={<Avatar alt="Natacha" src={ModSuitableIcon} />}
        label="Moderately Suitable For You"
        size="small"
    />
);
export const NotSuitableChip = () => (
    <Chip
        className="chip-job-matching chip-not-matching"
        avatar={<Avatar alt="Natacha" src={JobNotMatchingIcon} />}
        label="Job Not Matching Your Profile"
        size="small"
    />
);