import React, { useState } from 'react'
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Card, CardContent, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Stack, SvgIcon, Typography } from '@mui/material';
import { setApplication } from 'api/application';
import { setHideJob } from 'api/job';
import { useSnackbar } from 'components/snackbar';


const ExternalJobApplicationDialog = ({ onClose, jobId, jobDetails, tj_score, handleRefetchJob, downloadResume, externalJobStatus }) => {

    const [selectedOption, setSelectedOption] = useState('radio-1');
    const [isUpdating, setIsUpdating] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleButtons = (value) => {
        if (value === 'radio-1'){
            return (<>
                <Button fullWidth className="cancel-btn" variant="outlined" size="medium" onClick={onClose}>Cancel</Button>
                <Button fullWidth className="continue-btn" variant="contained" size="medium" disabled={isUpdating} onClick={handleAppliedJob}>  {isUpdating ? "Applied..." : "Apply"}</Button>
            </>)
        }
        else if (value === 'radio-2') {
            return (<>
                <Button fullWidth className="cancel-btn" variant="outlined" size="medium" onClick={downloadResume}>Select & Download Resume</Button>
                <Button fullWidth className="continue-btn" variant="contained" size="medium" disabled={isUpdating} onClick={handleContinueWithApplication}>                    
            {isUpdating ? 'In-progress...' : 'Continue with application'}</Button>
            </>)
        } else if (value === 'radio-3') {
            return (<>
                <Button fullWidth className="cancel-btn" variant="outlined" size="medium" onClick={onClose}>Cancel</Button>
                <Button fullWidth className="continue-btn" variant="contained" size="medium" disabled={isUpdating} onClick={handleSavedJobs}>{isUpdating ? "Moving..." : "Move to Saved Jobs"}</Button>
            </>)
        } else {
            return (<>
                <Button fullWidth className="cancel-btn" variant="outlined" size="medium" onClick={onClose}>Cancel</Button>
                <Button fullWidth className="continue-btn" variant="contained" size="medium" disabled={isUpdating} onClick={handleHideJob}>{isUpdating ? "Removing..." : "Remove Application"}</Button>
            </>)
        }
    };

    const handleSavedJobs = async () => {
        try {
            setIsUpdating(true);
            const response = await setApplication({ jobId, jobDetails, status: 'saved', tj_score });
            if (response && response?.success) {
                enqueueSnackbar('Job saved successfully', { variant: 'success' });
                handleRefetchJob();
                onClose();
            } else {
                enqueueSnackbar('Error in updating job status', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error in updating job status', error);
        } finally {
            setIsUpdating(false);
        }
    };

    const handleHideJob = async () => {
        try {
            setIsUpdating(true);
            const [ deleteApplicationResponse] = await Promise.all([  setApplication({ jobId, type: 'delete' })]);
            if (  deleteApplicationResponse && deleteApplicationResponse?.data && deleteApplicationResponse?.success) {
                enqueueSnackbar('Job removed successfully', { variant: 'success' });
                handleRefetchJob();
                onClose();
            } else {
                enqueueSnackbar('Failed to hide job. Please try again.', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error in hiding job', error);
        } finally {
            setIsUpdating(false);
        }
    };
    const handleAppliedJob = async () => {
        try {
            setIsUpdating(true); 
            const response = await setApplication({ jobId, status: 'applied', isStatusChange : true });
            if (response && response?.success) {
                enqueueSnackbar('Job applied successfully', { variant: 'success' }); 
                if (jobDetails) {
                    jobDetails.status = 'applied';
                    jobDetails.applied_at = new Date().toISOString();
                }
                handleRefetchJob(); 
                onClose(); 
            } else {
                enqueueSnackbar('Failed to apply for the job. Please try again.', { variant: 'error' }); 
            }
        } catch (error) {
            console.error('Error in applying for the job:', error);
            enqueueSnackbar('An unexpected error occurred. Please try again.', { variant: 'error' });
        } finally {
            setIsUpdating(false); 
        }
    };
    
    
    const handleContinueWithApplication = () => {
        const externalLink = jobDetails?.external_link;
        window.open(externalLink);
        handleJobInprogress();
      };
      const handleJobInprogress = async () => {
        try {
            setIsUpdating(true);            
            const response = await setApplication({ jobId, jobDetails, status: 'application pending', tj_score });            
            if (response?.success && response?.data != null) {
              const company = response?.data?.job_id?.company;
              const title = response?.data?.job_id?.title;
              enqueueSnackbar(`Your application to ${company} for ${title} is in progress`, { variant: 'info' });
              jobDetails.status = response?.data?.status;
                jobDetails.applied_at = response?.data?.applied_at;
                externalJobStatus();
              onClose();
            } else {
              enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
            }
        } catch (error) {
          console.error('Error submitting application:', error);
        } finally {
            setIsUpdating(false);
        }
      };

    return (
        <Card variant="outlined">
            <CardContent sx={{ overflow: '!important auto' }}>

                <Box mb={4}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography className="title">What would you like to do for this job?</Typography>
                        <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onClose}>
                            <SvgIcon sx={{ width: '20px', height: '20px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_456_20993)">
                                        <path d="M7 7L17 17M17 7L7 17L17 7Z" stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_456_20993">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Stack>
                </Box>

                <FormControl fullWidth>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedOption}
                        onChange={handleRadioChange}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value="radio-1"
                            control={<Radio />}
                            label={
                                <Box>
                                    <Typography className="option-title">Applied</Typography>
                                    <Typography className="option-text">This job would be visible to Applied job list</Typography>
                                </Box>
                            }
                            sx={{ marginBottom: "1rem" }}
                        />
                        <FormControlLabel
                            value="radio-2"
                            control={<Radio />}
                            className="active-radio"
                            label={
                                <Box>
                                    <Typography className="option-title">Apply for this job</Typography>
                                    <Typography className="option-text">Have your resume ready, you will be redirected to an external platform.</Typography>
                                </Box>
                            }
                            sx={{ marginBottom: "1rem" }}
                        />
                        <FormControlLabel
                            value="radio-3"
                            control={<Radio />}
                            label={
                                <Box>
                                    <Typography className="option-title">Save job for later</Typography>
                                    <Typography className="option-text">This would be moved to “Saved Job” from “Applied Job” section</Typography>
                                </Box>
                            }
                            sx={{ marginBottom: "1rem" }}
                        />
                        <FormControlLabel
                            value="radio-4"
                            control={<Radio />}
                            label={
                                <Box>
                                    <Typography className="option-title">Remove this Application</Typography>
                                    <Typography className="option-text">This job would not be visible to you anywhere</Typography>
                                </Box>
                            }
                        />
                    </RadioGroup>
                </FormControl>


                <Box mt={5}>
                    <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={2}>
                        {handleButtons(selectedOption)}
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )
}

export default ExternalJobApplicationDialog
