import { fetcher, endpoints, poster } from '../utils/axios';

export async function getApplicationApplyDetails(payload) {
    const URL = endpoints.application.getApplicationApplyDetails;
    return await fetcher([URL, { params:payload } ]);
}

export async function setApplication(payload) {
    const URL = endpoints.application.setApplication;
    return await poster([URL, payload ]);
}

export async function saveJobApplication(payload) {
    const URL = endpoints.application.saveJobApplication;
    return await poster([URL, payload ]);
}


export async function getApplicationList(payload) {
    const URL = endpoints.application.getApplicationList;
    return await fetcher([URL, { params : payload } ])
}

export async function getCandidateUpdatedAt(payload){
    const URL = endpoints.user.getCandidateUpdatedAt;
    return await fetcher([URL,{params:payload}])
}
