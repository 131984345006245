import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import UserProfile from './UserProfile';

function Profile() {
  const account =
    localStorage.getItem('TokenDetails') && JSON.parse(localStorage.getItem('TokenDetails'))?.authorization
      ? JSON.parse(localStorage.getItem('TokenDetails'))
      : {};

    return (
      <>
        <Helmet>
          <title> { `${account?.userType === 'b2b' ? 'Business' : 'User'} Profile | ${ process.env.REACT_APP_PORTAL_NAME }` }</title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                  Update {account?.userType === 'b2b' ? 'business' : ''} profile
              </Typography>
          </Stack>
        </Container>
    </>
  )
}

export default Profile