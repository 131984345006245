import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Link, IconButton, InputLabel, OutlinedInput, Stack, SvgIcon, Typography, TextField, FormControl, InputAdornment, Divider } from '@mui/material';
import { setApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';


const ExternalJobStatus = ({ onClose, tab, job }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleJobApplied = async () => {
    try {
      setIsSubmitting(true);
      if (job) {
        const jobId = tab === '2' ? job?._id : job?.job_id?._id;
        const response = await setApplication({ jobId: jobId, type: 'externalJobApplied', status: "applied" });
        if (response?.success && response?.data != null) {
          const company = response?.data?.job_id?.company;
          const title = response?.data?.job_id?.title;
          enqueueSnackbar(`Successfully applied to ${company} for ${title}`, { variant: 'success' });
          if (tab === '3') {
            job.status = response?.data?.status;
            job.applied_at = response?.data?.applied_at;
          } else {
            job.application_status = response?.data?.status;
          }
          onClose('applied');
        } else {
          enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error submitting application:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleDeletePendingApplication = async () => {
    try {
      const jobId = tab === '2' ? job?._id : job?.job_id?._id;
      const response = await setApplication({ jobId, type: 'delete' });
      if (response && response?.success) {
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box>
      <Stack justifyContent={'center'}>
        <Box sx={{ textAlign: 'center' }} mb={3}>
          <Typography className="title" mb={1}>
            Applied For Job</Typography>
          <Typography className="info-text">
            Were you able to apply for the job?</Typography>
        </Box>
        <Stack direction="row" alignItems={'center'} spacing={2.5}>
          <Button fullWidth variant="outlined" size="medium" onClick={handleDeletePendingApplication}>
            Couldn't Apply
          </Button>
          <Button fullWidth variant="contained" size="medium" onClick={handleJobApplied} disabled={isSubmitting}>
            {isSubmitting ? 'Applied...' : 'Yes Applied Successfully'}
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ExternalJobStatus;
