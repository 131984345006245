import { Avatar, Box, Button, Card, CardContent, Chip, Divider, Grid, List, ListItem, ListItemText, CircularProgress, Stack, SvgIcon, ToggleButton, AppBar, Toolbarup, Typography, ToggleButtonGroup, ListItemButton, ListItemIcon, Drawer, Dialog, DialogContent, IconButton, Menu, MenuItem, Skeleton } from '@mui/material'
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { jobDetails } from 'api/job';
import { useAuth } from 'hooks/useAuthContext';
import { getTimeDifferenceString, changeNameFormat, formatNumber, toTitleCase } from 'utils/Utils';
import { getApplicationApplyDetails } from 'api/application';
import StarIcon from '@mui/icons-material/Star';
import { LoginForm } from 'sections/auth/login';
import RegisterSection from 'components/login/RegisterSection';
import { saveJobApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const JobDetails = ({ jobId, setApplyButton, applyButton }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateContext } = useAuth();
  const [userId, setUserId] = useState(user?.id || null);

  const [job, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [requirementMatchItem] = useState(['Offered Compensation', 'Job Location', 'Job Format', 'Job Type', 'Job Shift', 'Notice Period']);
  const jobFullOverviewRef = useRef(null);
  const [openLogin, setOpenLogin] = useState(false);
  const jobOverviewRef = useRef(null);
  const jobDescRef = useRef(null);
  const [openRegister, setOpenRegister] = useState(false);
  const [status, setStatus] = useState(null);
  const { jobApply, setJobApply } = useAuth();
  // const [applyButton, setApplyButton] = useState(false);
  const [application, setApplication] = useState(null);

  const fetchJobDetails = async () => {
    let jobStatus;
    let jobData;
    let jobApplication;
    const response = await jobDetails({ id: jobId, userId: user?.id });
    if (response?.success) {
      if (response?.data?.job) {
        jobData = response?.data?.job;
        jobApplication = response?.data?.jobIdInCandidate;
        jobStatus = response?.data?.jobIdInCandidate?.status;
        setJobs(jobData || []);
        setApplication(jobApplication || null);
        setStatus(jobStatus || null);
      }
      else setJobs(response?.data);
    } else console.error("Failed to fetch job info:", response.message);
    return { jobData, jobStatus, jobApplication };
  };

  const fetchCandidateDetails = async () => {
    if (user?.id && user?.stages === 4) {
      const responseCandidate = await getApplicationApplyDetails({ id: jobId });
      if (responseCandidate?.success && responseCandidate?.data) {
        const { data } = responseCandidate;
        setCandidateDetails(data);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    loadDetails();
  }, [jobId]);

  useEffect(() => {
    loadDetails();
  }, [user, applyButton]);

  const loadDetails = async () => {
    let jobStatus;
    let jobData;
    let jobApplication;
    try {
      ({ jobData, jobStatus, jobApplication } = await fetchJobDetails());
      // await fetchCandidateDetails();
    } catch (error) {
      console.error("Error fetching job or candidate info:", error);
    } finally {
      setLoading(false);
      //demo
      if (applyButton && user) {
        handleNavigation(jobData, jobStatus, jobApplication);
      }
      //demo
    }
  };

  // overview description toggle
  const [alignment, setAlignment] = React.useState('job-overview');

  const handleToggleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      switch (newAlignment) {
        case 'job-overview':
          jobFullOverviewRef.current.scrollTo({
            top: jobOverviewRef.current.offsetTop,
            behavior: 'smooth',
          });
          break;
        case 'job-desc':
          jobFullOverviewRef.current.scrollTo({
            top: jobDescRef.current.offsetTop,
            behavior: 'smooth',
          });
          break;
        default:
          break;
      }
    }
  };

  const handleApplyJob = () => {    
    setApplyButton(true);
    if (!user?.id) {
      setOpenLogin(true);
    } else {
      if (user?.stages < 4) {
        handleSaveJob();
      } else {
        setJobApply({ job: ((status === 'saved' || status === null) ? job : application), openChatBot: ((status === 'saved' || status === null) ? true : false), resume: null, answer: null, tab: ((status === 'saved' || status === null) ? '2' : '3'), activeTab: 'applied-jobs', filter: job?._id });
      }
      navigate('/');
    }
  }

  const handleSaveJob = async () => {
    try {
      setLoading(true);
      const response = await saveJobApplication({ jobId: job?._id, companyId: job?.company_id, type: 'saved' });
      if (response?.success && response?.data != null) {
        enqueueSnackbar(response?.message, { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to save the application. Please try again.', { variant: 'error' });
      }
    } catch (e) {
      console.error('Error saving for later:', e);
    } finally {
      setLoading(false);
    }
  }

  const handleLogin = async () => {
    updateContext();
    setOpenLogin(false);
  }

  const handleRegister = () => {
    handleSaveJob();
  }
  
  //demo
  const handleRedirectToDashboard = async() => {
    updateContext();   
    setOpenLogin(false); 
  }
  const handleNavigation = (jobData, jobStatus, jobApplication) => { 
    const status = jobStatus || null;
    if (!user?.stages || user?.stages < 4) {
      handleSaveJob();
    }else{      
      setJobApply({ job: ((status === 'saved' || status === null) ? jobData : jobApplication), openChatBot: ((status === 'saved' || status === null) ? true : false), resume: null, answer: null, tab: ((status === 'saved' || status === null) ? '2' : '3'), activeTab: 'applied-jobs', filter: job?._id });
    }
    navigate('/');
  }
  //demo

  if (loading) {
    return (
      <Box className="job-full-details"><Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
        <CircularProgress size={24} />
      </Stack>
      </Box>
    );
  }

  return (

    <Box className="job-full-details">
      <Box className="job-full-details-header">
        <Box className="header-top">
          <Stack direction="row" justifyContent={'space-between'} spacing={2}>
            <Box>
              <Typography className="title">{job?.title || 'NA'}</Typography>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                {job?.is_company_name_confidential ? <Typography className="company-name">Company Name - Confidential</Typography> : <Typography className="company-name">{job?.company ? toTitleCase(job?.company) : 'NA'}</Typography>}
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography className="city-name">{job?.location || 'N/A'}</Typography>
              </Stack>
            </Box>
            <Stack direction="row" spacing={3}>
              {(status === 'saved' || status === null || status === 'application pending') ? (
                <Button sx={{ width: '150px' }}
                  variant="contained"
                  size="large"
                  className={job?.external_link ? "external-apply-now-btn" : "apply-now-btn"}
                  endIcon={job?.external_link ? < SvgIcon >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5413 0.0581763C15.7159 0.130897 15.8613 0.276338 15.934 0.450867H15.9413C15.9776 0.538131 15.9995 0.632668 15.9995 0.727205V5.03953C15.9995 5.43949 15.6722 5.76673 15.2723 5.76673C14.8723 5.76673 14.5451 5.43949 14.5451 5.03953V2.47977L8.51653 8.50829C8.37109 8.64646 8.18929 8.71918 8.00021 8.71918C7.81114 8.71918 7.62934 8.65373 7.4839 8.50829C7.20029 8.22468 7.20029 7.76654 7.4839 7.48293L13.5124 1.45441H10.9527C10.5527 1.45441 10.2255 1.12717 10.2255 0.727205C10.2255 0.327242 10.5527 0 10.9527 0H15.265C15.3595 0 15.4541 0.0218161 15.5413 0.0581763ZM14.5375 8.0013C14.5375 7.60134 14.8648 7.27409 15.2648 7.27409C15.6647 7.27409 15.992 7.60134 15.992 8.0013V13.0554C15.992 14.677 14.6684 16.0005 13.0468 16.0005H2.94591C1.32424 16.0005 0.000732422 14.677 0.000732422 13.0554V2.9545C0.000732422 1.33283 1.32424 0.00932173 2.94591 0.00932173H7.99998C8.39994 0.00932173 8.72719 0.336564 8.72719 0.736526C8.72719 1.13649 8.39994 1.46373 7.99998 1.46373H2.94591C2.12417 1.46373 1.45514 2.13276 1.45514 2.9545V13.0554C1.45514 13.8771 2.12417 14.5461 2.94591 14.5461H13.0468C13.8685 14.5461 14.5375 13.8771 14.5375 13.0554V8.0013Z" fill="white" />
                    </svg>
                  </SvgIcon> : null}
                  onClick={handleApplyJob}
                >
                  Apply Now
                </Button>
              ) : (
                <Button sx={{ width: '150px' }}
                  variant="contained"
                  size="large"
                  className={"apply-now-btn"}
                  disabled
                >{changeNameFormat(status)}</Button>
              )}
            </Stack>
          </Stack>
        </Box>
        <Box className="header-bottom my-internal-jobs">
          <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
            <ToggleButtonGroup
              className="job-desc-toggle-btn"
              color="primary"
              value={alignment}
              exclusive
              onChange={handleToggleChange}
              aria-label="Platform"
            >
              <ToggleButton value="job-overview">Job Overview</ToggleButton>
              <ToggleButton value="job-desc">Job Responsibilities</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>
      </Box>

      {/* job overview block */}
      <Box ref={jobFullOverviewRef} className="job-full-overview" sx={{ height: 'calc(100vh - 32vh)!important' }}>
        <Box ref={jobOverviewRef} id="job-overview">
          <Box className="job-overview-wrapper" mb={4}>
            <Typography className="title">Job Overview</Typography>
            <Typography className="about-post-info" style={{ textAlign: 'justify', marginBottom: 15 }}>
              {job?.company_overview}
            </Typography>
            <Typography className="about-post-info" style={{ textAlign: 'justify' }}>
              {job?.job_overview}
            </Typography>
          </Box>

          {/* Job Suitability Analysis */}          
          <NoMaxWidthTooltip title="Please sign up or login to view your suitabilty for this job" arrow followCursor placement='top'>
          <Card variant="outlined" className="requirements-match-card job-suit-analysis" sx={{ marginBottom: 3 }}>
            <CardContent>

              <Box className="lock-icon">
                <SvgIcon>
                  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.385 8.61573H17.0769V7.07651C17.0769 4.54843 15.728 2.21181 13.5391 0.948452C11.349 -0.316151 8.65132 -0.316151 6.46134 0.948452C4.27248 2.2119 2.92351 4.5486 2.92351 7.07651V8.61573H2.61542C1.42812 8.6215 0.468123 9.58264 0.461182 10.7688V21.8458C0.468105 23.0331 1.4281 23.9931 2.61542 24H17.3847C18.572 23.9931 19.532 23.0331 19.5389 21.8458V10.7688C19.532 9.58267 18.5723 8.62152 17.385 8.61573ZM10.9234 17.5384C10.9234 18.0484 10.5104 18.4615 10.0003 18.4615C9.49033 18.4615 9.07727 18.0484 9.07727 17.5384V15.0773C9.07727 14.5673 9.49033 14.1542 10.0003 14.1542C10.5104 14.1542 10.9234 14.5673 10.9234 15.0773V17.5384ZM4.76996 7.07648C4.76996 5.20841 5.76689 3.48105 7.38459 2.54646C9.00345 1.61298 10.9971 1.61298 12.6162 2.54646C14.2339 3.48108 15.2308 5.20838 15.2308 7.07648V8.6157H4.77002L4.76996 7.07648Z" fill="white" />
                  </svg>
                </SvgIcon>
              </Box>

              <Box>
                <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                  <Box>
                    <Typography className="title">Job Suitability Analysis</Typography>
                    <Typography className="info-text" sx={{ color: '#374151!important' }}>Login to assess your suitability for this job</Typography>
                  </Box>
                  {!user &&
                  <Button variant="contained" size="small" disableElevation 
                  onClick={handleApplyJob} >Login / Register</Button>}
                </Stack>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

              <Typography className="title" sx={{ opacity: 0.6 }}>Requirements Match</Typography>
              <Typography className="info-text" sx={{ opacity: 0.6 }}>Matched against your educational background & work experience</Typography>

              <Box className="requirements-match" mt={1.5} sx={{ opacity: 0.6 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box>
                      <Stack direction="row" align spacing={0.5}>
                        <Box>
                          <Typography className="req-title">Required Education</Typography>
                          <Typography className="req-text">{job?.qualifications?.join(' | ')}</Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Stack direction="row" spacing={0.5}>

                        <Box>
                          <Typography className="req-title">Required Work Experience</Typography>
                          <Typography className="req-text">{job?.experience ? `${job?.experience} Years` : 'N/A'}</Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Stack direction="row" spacing={0.5}>

                        <Box>
                          <Typography className="req-title">Industry Sector</Typography>
                          <Typography className="req-text">{job?.industry_sector || 'N/A'}</Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Stack direction="row" spacing={0.5}>

                        <Box>
                          <Typography className="req-title">Required Skills</Typography>
                          <Typography className="req-text" sx={{ textDecoration: 'underline' }}>
                            {job?.core_skills?.length > 0 && (
                              <>
                                {`${job.core_skills.length} Core`}
                                <br />
                              </>
                            )}
                            {job?.key_skills?.length || 0} skills listed</Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

              <Box className="preferences-match" sx={{ opacity: 0.6 }}>
                <Typography className="title">Job Details</Typography>

                <Box mt={2}>
                  <Stack spacing={1.5}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography className="pref-match-title">Offered Compensation</Typography>
                          </Stack>
                        </Box>
                      </Grid>

                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'flex-start'} spacing={0.7}>
                            <Typography className="pref-match-info">
                                ₹{formatNumber(job?.salary)}
                                {job?.compensation && `(${job?.compensation})`}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography className="pref-match-title">Job Location</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'flex-start'} spacing={0.7}>
                            <Typography className="pref-match-info">{job?.location || 'NA'}</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography className="pref-match-title">Job Format</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Typography className="pref-match-info">{job?.work_mode || 'NA'}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography className="pref-match-title">Job Type</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Typography className="pref-match-info">{job?.job_type || 'NA'}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography className="pref-match-title">Job Shift</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Typography className="pref-match-info">{job?.job_shift ? `${job?.job_shift}` : 'NA'}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography className="pref-match-title">Notice Period</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Box>
                          <Stack direction="row" alignItems={'flex-start'} spacing={0.7}>
                          <Typography className="pref-match-info">{job?.notice_period ? ((job?.notice_period === '0 days (immediate)' || job?.notice_period === 'No preference') ? `${job?.notice_period}` : `Upto ${job?.notice_period} days`) : 'NA'}</Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>          
          </NoMaxWidthTooltip>

          <Card variant="outlined" className="skills-match-card" sx={{ marginBottom: 3 }}>
            <CardContent>
              <Box>
                <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                  <Box>
                    <Typography className="title">Skills</Typography>
                  </Box>
                </Stack>
              </Box>

              <Stack direction="row" flexWrap="wrap" sx={{ marginTop: '1rem', marginBottom: '1rem' }} >
                {job?.key_skills
                  ? job?.key_skills.map((skill, index) => (
                    <Chip
                      key={index}
                      label={
                        job?.core_skills?.includes(skill) ? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                          <span>{skill}</span>
                          <StarIcon className="star-icon" sx={{ color: '#faaf00', padding: '5px', marginLeft: 'auto' }} />
                        </Box> : skill
                      }
                      variant="outlined"
                      sx={{ paddingLeft: '0px !important' }}
                    />
                  ))
                  : 'No Skills'}
              </Stack>
            </CardContent>
          </Card>
        </Box>

        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

        <Box ref={jobDescRef} id="job-desc" className="job-description" sx={{ marginBottom: 'calc(100vh - 40vh) !important' }}>
          <Typography className="title">Job Responsibilities</Typography>
          <Typography className="info-text list-style" >
            <div dangerouslySetInnerHTML={{ __html: job?.description }} />
          </Typography>
        </Box>
      </Box>

      {/* login & register */}
      <Dialog
        open={openLogin}
        onClose={() => setOpenLogin(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '600px'
          },
        }}
      >
        {openRegister ? <RegisterSection jobId={jobId} setOpenRegister={setOpenRegister} onRegister={handleRegister} /> :
          <LoginForm jobId={jobId} setOpenRegister={setOpenRegister} 
          // onLogin={handleLogin}
          onLogin={handleRedirectToDashboard}//demo
           />}
      </Dialog>
    </Box>
  )
}

export default JobDetails
