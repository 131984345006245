import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ pdfData }) => {
    const [numPages, setNumPages] = useState(null);
    // Switch to the new PDF when it's fully loaded
    const handleDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px' }}>
            {/* Visible PDF */}
            {pdfData && (
                <Document
                    file={pdfData}
                    onLoadSuccess={handleDocumentLoadSuccess}
                    loading={null}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <div key={`page_${index + 1}`} className="pdf-page-container" style={{ marginBottom: (index + 1 < numPages ? '10px' : '0') }}>
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                loading={null}
                            />
                        </div>
                    ))}
                </Document>
            )}
        </div>
    );
};

export default PDFViewer;
