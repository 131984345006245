import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const BulletPointEditor = ({ value, onSetTemp, index, title, isFocus }) => {
  const quillRef = useRef(null);
  const [valueState, setValue] = React.useState(value || '');
  // const [customValue, setCustomValue] = React.useState(value || '');

  useEffect(() => {
    setValue(value);
    // setCustomValue(value);
  }, [title]);

  useEffect(() => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      if (isFocus) {
        editor.focus(); // Automatically focus the editor
        const length = editor?.getLength();
        editor.setSelection(length, length); // Set cursor to the end of the text
      }
    }
  }, [isFocus]);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(valueState, 'text/html');
    const listItems = doc.querySelectorAll('li');
    const fragment = document.createDocumentFragment();
    listItems.forEach((item) => {
      if (item.innerHTML.trim() !== '' && item.innerHTML.trim() !== '<br>') {
        fragment.appendChild(item.cloneNode(true));
      }
    });
    const ul = doc.querySelector('ul') || doc.body;
    ul.innerHTML = '';
    ul.appendChild(fragment);
    let removeEmptyPoint = doc.body.innerHTML.trim();
    if (removeEmptyPoint === '' || removeEmptyPoint === '<ul></ul>') {
      removeEmptyPoint = valueState;
    }
    onSetTemp(removeEmptyPoint, index);
  }, [valueState]);

  // Apply bullet format when the editor gets focus
  const handleFocus = () => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      // Check if the editor is not already in bullet list mode
      if (editor.getFormat().list !== 'bullet') {
        editor.format('list', 'bullet');
      }
    }
  };

  const handleChange = (content, delta, source, editor) => {
    const quill = quillRef?.current?.getEditor();
    const range = quill?.getSelection();
    const text = editor?.getText().trim(); // Get editor text without extra spaces
    // Ensure the new content is wrapped in a bullet point if not empty    
    
    if (text.length > 0) {
      if (delta?.ops[delta?.ops?.length - 1]?.attributes?.list === null) {
        quill?.deleteText(range.index, 1);
      } else {
        quill?.format('list', 'bullet');
      }
      // if (!(delta?.ops[delta?.ops?.length - 1]?.attributes?.list === 'bullet')) {
      //   setCustomValue(editor.getHTML());
      // }
    }
    setValue(editor.getHTML());
    // If the text is fully deleted, reset the editor to bullet point
    if (text?.length === 0) {
      quill?.setContents([{ insert: '\n' }]); // Reset the content
      quill?.setSelection(0); // Move the cursor to the start
    }
  };

  const modules = {
    toolbar: false, // Hide toolbar for simplicity
  };

  return (
    <div className="quill-container">
      <ReactQuill
        ref={quillRef}
        value={valueState}
        onChange={handleChange}
        modules={modules}
        placeholder="Start writing here..."
        formats={['list', 'bullet']}
      />
    </div>
  );
};

export default BulletPointEditor;
