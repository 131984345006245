import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  OutlinedInput,
  ListItemText
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CandidateJobPreferencesRule } from 'models/CandidateProfileSchema';
import FormProvider from 'hooks/form/form-provider';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
const dayjs = require('dayjs');
import { addJobPreferance, addSingleJobPreference } from 'api/users';
import { formatCurrency } from 'utils/Utils';
import { cleanData, clusteredData } from 'data/city.js';

const cityData = [...clusteredData, ...cleanData];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

const JopApplicationDrawer = ({ onClose, data, onUpdatePreference }) => {
  const [values, setValues] = useState(0);

  const [openWorkFormat, setOpenWorkFormat] = useState(false);
  const [openJobType, setOpenJobType] = useState(false);
  const [openJobShifts, setOpenJobShifts] = useState(false);
  const [isBuyout, setIsBuyout] = useState(false);
  const [onSelect, setOnSelect] = useState(0);

  const workModelFormat = ['In office', 'Work from Home', 'Hybrid'];
  const jobType = ['Full time', 'Part time', 'Temporary', 'Internship', 'Trainee', 'Freelancer (Self employed)'];
  const preferredJobShifts = ['Any', 'Daytime', 'Evening', 'Night'];

  const defaultValues = {
    notice_period: '',
    buyout_period: '',
    is_negotiable: false,
    total_ctc: null,
    compensation: '',
    fixed_ctc: null,
    variable_ctc: null,
    other_ctc: '',
    expected_ctc: '',
    work_format: ['In office'],
    job_type: ['Full time'],
    preferred_job_shifts: ['Daytime'],
    prefered_location: [],
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(CandidateJobPreferencesRule(values, isBuyout)),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { touchedFields, errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  const fixed = watch('fixed_ctc');
  const variable = watch('variable_ctc');

  useEffect(() => {
    const subscription = watch((value) => {
      const preferredLocations = value?.prefered_location;
      if (Array.isArray(preferredLocations)) {
        if (preferredLocations.length > 5) {
          const limitedLocations = preferredLocations.slice(0, 5);
          setValue('prefered_location', limitedLocations);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    fetchjobs();
  }, []);

  useEffect(() => {
    const total = parseInt(fixed || 0) + parseInt(variable || 0);
    setValue('total_ctc', total);
  }, [fixed, variable]);

  useEffect(() => {
    if (onSelect > 5) setTimeout(() => setOnSelect(5), 3000)
  }, [onSelect]);

  const fetchjobs = async () => {
    try {
      if (data) {
        Object.keys(defaultValues).forEach((field) => {
          setValue(field, data?.[field]);
        });
        setIsBuyout(data?.is_negotiable || false);
        setOnSelect(data?.prefered_location?.length || 0);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const onSubmit = handleSubmit(async (formData) => {
    const response = await addJobPreferance(formData);
    if (response?.success === true && response?.data) {
      onUpdatePreference(response.data?.job_preferences);
      onClose();
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  });

  const handleSingleChange = async (data) => {
    const response = await addSingleJobPreference(data);
    if (response?.success === true && response.data) {
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  }

  const handleChange = (name, previousValue, value, allValue) => {
    if (value?.includes('Any') && !previousValue?.includes('Any')) {
      setValue(name, allValue);
      // handleSingleChange({ [name]: allValue });
    } else if (previousValue?.includes('Any') && !value?.includes('Any')) {
      setValue(name, []);
      // handleSingleChange({ [name]: [] });
    } else {
      setValue(name, value);
      // handleSingleChange({ [name]: value });
    }
  }

  return (
    <Box sx={{ width: 450 }} className="job-app-drawer-container" role="presentation" >
      <Box className="drawer-header">
        <Stack direction="row" alignItems={'flex-start'} spacing={1}>
          <IconButton aria-label="delete" onClick={onClose} sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <SvgIcon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z" fill="white" />
              </svg>
            </SvgIcon>
          </IconButton>

          <Box>
            <Typography className="title">Job Application</Typography>
            <Typography className="sub-text">Please help us with answers to few questions to complete your application</Typography>
          </Box>
        </Stack>
      </Box>

      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className="drawer-content">
          <Box mb={3}>
            <Typography className="step-heading" variant="h6">
              Current Job Details
            </Typography>
          </Box>
          <Stack spacing={2}>
            {/* notice period */}
            <FormControl fullWidth size="small" error={!!errors.notice_period}>
              <InputLabel id="notice_period">Notice Period</InputLabel>
              <Controller
                control={control}
                name="notice_period"
                defaultValue={defaultValues?.buyout_period || ''}
                render={({ field }) => (
                  <Select
                    {...field}
                    {...register('notice_period')}
                    labelId="notice_period"
                    label="Notice Period"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      // handleSingleChange({ notice_period: e.target.value });
                    }
                    }
                  >
                    <MenuItem value="0 days (immediate)">0 days (immediate)</MenuItem>
                    <MenuItem value="15">15 Days</MenuItem>
                    <MenuItem value="30">30 Days</MenuItem>
                    <MenuItem value="45">45 Days</MenuItem>
                    <MenuItem value="60">60 Days</MenuItem>
                    <MenuItem value="75">75 Days</MenuItem>
                    <MenuItem value="90">90 Days</MenuItem>
                    <MenuItem value="90+">90+ Days</MenuItem>
                  </Select>
                )}
              />
              {errors.notice_period && <FormHelperText error>{errors.notice_period?.message}</FormHelperText>}
            </FormControl>
            {/* buyout period */}
            <FormControl fullWidth>
              <Controller
                control={control}
                name="buyout_period"
                defaultValue={defaultValues?.buyout_period || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="buyout_period"
                    label="If notice period is negotiable, please state how much."
                    name="buyout_period"
                    disabled={!isBuyout}
                    variant="outlined"
                    size="small"
                    error={isBuyout && !!errors.buyout_period}
                    helperText={isBuyout && errors.buyout_period?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      // handleSingleChange({ buyout_period: e.target.value });
                    }}
                  />
                )}
              />
            </FormControl>
            {/* is negotiable */}
            <FormControl error={!!errors.is_negotiable} className="neogtiable-chk" size="small">
              <Controller
                name="is_negotiable"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        {...register('is_negotiable')}
                        id="is_negotiable"
                        name="is_negotiable"
                        checked={isBuyout}
                        onChange={(event) => {
                          let dataToUpdate = { is_negotiable: event.target.checked };
                          if (!event.target.checked) {
                            setValue('buyout_period', '');
                            dataToUpdate = { ...dataToUpdate, buyout_period: '' };
                          }
                          field.onChange(event.target.checked);
                          setIsBuyout(event.target.checked);
                          // handleSingleChange(dataToUpdate);
                        }}
                      />
                    }
                    label="Notice period is negotiable"
                    id="is_negotiable"
                    sx={{ paddingLeft: 2 }}
                  />
                )}
              />
              {errors.is_negotiable && (
                <Typography variant="caption" color="error">
                  {errors.is_negotiable.message}
                </Typography>
              )}
            </FormControl>
          </Stack>
          <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginBottom: '1rem' }} />

          <Box className="annual-compensation" mb={3}>
            <Box mb={3}>
              <Typography className="step-heading" variant="h6">
                Annual Compensation
              </Typography>
              <Typography className="step-info" variant="body2">
                Please tell us your annual compensation with current company
              </Typography>
            </Box>
            <Box>
              <Stack spacing={2}>
                {/* fixed ctc */}
                <FormControl fullWidth error={!!errors?.fixed_ctc} >
                  <Controller
                    control={control}
                    name="fixed_ctc"
                    defaultValue={defaultValues?.fixed_ctc || ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="fixed_ctc"
                        name="fixed_ctc"
                        label="Fixed Component"
                        variant="outlined"
                        size="small"
                        value={field.value ? formatCurrency(field.value) : ''}
                        error={!!errors.fixed_ctc}
                        onChange={(e) => {
                          const rawValue = parseInt(e.target.value?.replace(/,/g, '')) || null;
                          const variableAmt = parseInt(getValues('variable_ctc')) || null;
                          field.onChange(rawValue);
                          // handleSingleChange({ fixed_ctc: rawValue, total_ctc: (rawValue + variableAmt) });
                        }}
                      />
                    )}
                  />
                  {errors?.fixed_ctc && <FormHelperText error>{errors?.fixed_ctc?.message}</FormHelperText>}
                </FormControl>
                {/* variable ctc */}
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="variable_ctc"
                    defaultValue={defaultValues?.variable_ctc || ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="variable_ctc"
                        name="variable_ctc"
                        label="Variable Component"
                        variant="outlined"
                        size="small"
                        value={field.value ? formatCurrency(field.value) : ''}
                        error={!!errors.variable_ctc}
                        helperText={errors.variable_ctc?.message}
                        onChange={(e) => {
                          const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                          const fixedAmt = parseInt(getValues('fixed_ctc')) || null;
                          field.onChange(rawValue);
                          // handleSingleChange({ variable_ctc: rawValue, total_ctc: (rawValue + fixedAmt) });
                        }}
                      />
                    )}
                  />
                </FormControl>
                {/* other ctc */}
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="other_ctc"
                    defaultValue={defaultValues?.other_ctc || ''}
                    rules={{
                      validate: (value) => {
                        const wordCount = value.trim().split(/\s+/).length;
                        return wordCount <= 25 || "Maximum 25 words allowed.";
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="other_ctc"
                        name="other_ctc"
                        label="Other Perks"
                        variant="outlined"
                        size="small"
                        defaultValue={defaultValues?.other_ctc || ''}
                        error={!!errors.other_ctc}
                        helperText={errors.other_ctc?.message}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const words = inputValue.trim().split(/\s+/);
                          if (words.length <= 25) {
                            field.onChange(inputValue);
                            clearErrors('other_ctc');
                          } else {
                            setError('other_ctc', { message: "Maximum 25 words allowed." });
                          }
                          // handleSingleChange({ other_ctc: e.target.value });
                        }}
                      />
                    )}
                  />
                </FormControl>
                <Box className="annual-compensation compensation-det-block">
                  {watch('total_ctc') && watch('total_ctc') > '0' ? (
                    <Box className="after-comp-amt">
                      <Typography className="comp-text">Your Total Annual Compensation</Typography>
                      <Typography className="comp-amt" mt={2}>
                        ₹{watch('total_ctc') && `${new Intl.NumberFormat('en-IN').format(watch('total_ctc'))}`}
                      </Typography>
                      <Typography className="comp-text">
                        {watch('other_ctc') && `Along with ${watch('other_ctc')}`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="before-comp-amt">
                      <Typography className="comp-text">
                        Please enter your compensation details
                      </Typography>
                    </Box>
                  )}
                </Box>

              </Stack>
            </Box>
          </Box>

          <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

          <Box className="current-job-details">
            <Typography className="title">Job Preferences</Typography>
            <Typography className="sub-text">This would help us identify the best suited jobs for you
            </Typography>

            <Stack spacing={2} mt={2}>
              {/* expected ctc */}
              <FormControl fullWidth size="small" error={!!errors.expected_ctc}>
                <Controller
                  control={control}
                  name="expected_ctc"
                  defaultValue={defaultValues?.expected_ctc || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="expected_ctc"
                      name="expected_ctc"
                      label="Annual Expected Total Compensation (fixed + variable)"
                      variant="outlined"
                      size="small"
                      value={field.value ? formatCurrency(field.value) : ''}
                      error={!!errors.expected_ctc}
                      helperText={errors.expected_ctc?.message}
                      onChange={(e) => {
                        const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                        field.onChange(rawValue);
                        // handleSingleChange({ expected_ctc: rawValue });
                      }}
                    />
                  )}
                />
              </FormControl>
              {/* work format */}
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="work_format"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      options={workModelFormat}
                      disableCloseOnSelect={true}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        field.onChange(value);
                        // handleSingleChange({ work_format: value });
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Preferred Work Format"
                          error={!!errors.work_format}
                          helperText={errors.work_format?.message}
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText>Select one or more options</FormHelperText>
              </FormControl>
              {/* job type */}
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="job_type"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      options={jobType}
                      getOptionLabel={(option) => option}                      
                      disableCloseOnSelect={true}
                      onChange={(event, value) => {
                        field.onChange(value);
                        // handleSingleChange({ job_type: value });
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Preferred Job Type"
                          error={!!errors.job_type}
                          helperText={errors.job_type?.message}
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText>Select one or more options</FormHelperText>
              </FormControl>
              {/* job shift */}
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="preferred_job_shifts"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      options={preferredJobShifts}
                      getOptionLabel={(option) => option}
                      disableCloseOnSelect={true}
                      onChange={(event, value) => {
                        handleChange(field?.name, field?.value, value, preferredJobShifts);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Preferred Working Hours"
                          variant="outlined"
                          error={!!errors.preferred_job_shifts}
                          helperText={errors.preferred_job_shifts?.message}
                        />
                      )}
                    />
                  )}
                /><FormHelperText>Select one or more options</FormHelperText>
              </FormControl>
              {/* location */}
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="prefered_location"
                  render={({ field }) => (
                    <TypeAhead
                      {...field}
                      {...register('prefered_location')}
                      label="Preferred Locations (select upto 5)"
                      disableCloseOnSelect={true}
                      multiple={true}
                      selectAsCheckbox={true}
                      isCloseHide={true}
                      data={[{ name: 'Any', value: 'Any' }, ...cityData]}
                      // selectionKey="value"
                      displayTextKey="name"
                      maxSelection={5}
                      onChange={(e, dataObjs, values) => {
                        if (values?.length <= 5) {
                          setOnSelect(values?.length);
                          // handleSingleChange({ prefered_location: values });
                        } else {
                          setOnSelect(onSelect + 1);
                        }
                      }}
                    />
                  )}
                />
                {(onSelect && onSelect > 5) ? <FormHelperText error>You can select up to 5 locations only</FormHelperText> : null}
                <FormHelperText>Select one or more options</FormHelperText>
              </FormControl>
            </Stack>
          </Box>
        </Box>

        <Box className="drawer-footer">
          <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" className="cancel-btn" onClick={onClose}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" size="large" className="update-btn" onClick={onSubmit} disabled={isSubmitting}>
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default JopApplicationDrawer;
