import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Dialog, FormControl, IconButton, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import ChatResumeOptions from './ChatResumeOptions';
import { getResumesData } from 'api/users';
import SelectResumeOptions from './SelectResumeOptions';
import { useSnackbar } from 'components/snackbar';
import { setApplication } from 'api/application';
import { useAuth } from 'hooks/useAuthContext';
const resumeImg = `${process.env.REACT_APP_HOMEPAGE}assets/resume.png`;

const ChatBotDrawer = ({ onClose, onSelected, questions, tab, data, candidateDetails, matchingSkills }) => {

  const { jobApply, setJobApply } = useAuth();
  let scrollRef = useRef(null);

  const [resumeSelected, setResumeSelected] = useState(null);
  const [showQuestions, setShowQuestions] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [answers, setAnswers] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [chatCompleted, setChatCompleted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [resumeData, setResumeData] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTextField, setShowTextField] = useState(true);
  const [editQusIndex, setEditQusIndex] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [answers, resumeData, showQuestions, chatCompleted]);

  const fetchData = async () => {
    try {
      const response = await getResumesData();
      if (response?.success && response?.data) {
        setResumeData(response?.data);
      } else {
        onClose();
        enqueueSnackbar('Please check that you have added your resume.', { variant: 'error' });
      }
    } finally {
      setLoading(false);
      if (jobApply && jobApply?.job?._id === data?._id) {
        if (jobApply?.resume) {
          setResumeSelected(jobApply?.resume);;
        }else{
          return;
        }
        if (questions && questions?.length > 0) {
          setShowQuestions(jobApply?.resume ? true : false);
        } else {
          setChatCompleted(true);
          setShowQuestions(false);
          setShowTextField(false);
        }
        if (jobApply?.answer && Object.keys(jobApply?.answer)?.length > 0) {
          setAnswers(jobApply?.answer);
          if (Object.keys(jobApply?.answer)?.length < questions?.length) {
            const index = Object.keys(jobApply?.answer)?.length;
            setCurrentQuestionIndex(index);
          } else {
            setChatCompleted(true);
            setShowQuestions(false);
            setShowTextField(false);
          }
        }
      }
    }
  };

  const handleSelect = (item, index) => {
    setSliderIndex(index);
    setResumeSelected(item);
    setJobApply({ job: data, openChatBot: true, resume: item, answer: answers });
    if (questions && questions?.length > 0) {
      setShowQuestions(true);
    } else {
      setShowQuestions(false);
      setChatCompleted(true);
      setShowTextField(false);
    }
  };

  const handleSelected = (resume, index) => {
    setResumeSelected(resume);
    setJobApply({ job: data, openChatBot: true, resume: resume, answer: answers });
    setSliderIndex(index);
    if (questions && questions?.length > 0) {
      setShowQuestions(true);
    }
    handleClosed();
  };

  const handleInputChange = (e) => {
    setCurrentAnswer(e.target.value);
  };

  const handleSetAnswer = () => {
    if (currentAnswer === '') {
      enqueueSnackbar('Please write your answer.', { variant: 'error' });
    } else if (editQusIndex !== null) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questions[editQusIndex]]: currentAnswer,
      }));
      setJobApply({ job: data, openChatBot: true, resume: resumeSelected, answer: { ...answers, [questions[editQusIndex]]: currentAnswer } });
      setCurrentAnswer('');
      if (chatCompleted) {
        setShowTextField(false);
      }
      setEditQusIndex(null);
    } else {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questions[currentQuestionIndex]]: currentAnswer,
      }));
      setJobApply({ job: data, openChatBot: true, resume: resumeSelected, answer: { ...answers, [questions[currentQuestionIndex]]: currentAnswer } });
      setCurrentAnswer('');
      if (questions && questions?.length > currentQuestionIndex + 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      } else {
        setShowQuestions(false);
        setChatCompleted(true);
        setShowTextField(false);
      }
    }
  };

  const handleChangeResume = () => {
    setOpen(true);
  };

  const handleClosed = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (candidateDetails && matchingSkills && data && resumeSelected) {
        const jobDetails = tab === '2' ? data : data?.job_id;
        const response = await setApplication({
          candidateDetails,
          matchingSkills,
          jobDetails,
          status: 'applied',
          resumeData: resumeSelected,
          tj_score: data?.tj_score,
          answers,
        });
        if (response?.success && response?.data != null) {
          const company = response?.data?.job_id?.company;
          const title = response?.data?.job_id?.title;
          enqueueSnackbar(`Successfully applied to ${company} for ${title}`, { variant: 'success' });
          setJobApply(null);
          if (tab === '3') {
            data = response?.data;
          } else {
            data.application_status = response?.data?.status;
          }
          onClose();
          onSelected(resumeSelected);
        } else {
          enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error submitting application:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateAnswer = (index, answer) => {
    setEditQusIndex(index);
    setCurrentAnswer(answer);
    if (!showTextField) {
      setShowTextField(true);
    }
  };

  return (
    <Box sx={{ width: 450 }} className="job-app-drawer-container chat-bot-drawer-container" role="presentation">
      <Box className="drawer-header">
        <Stack direction="row" alignItems={'flex-start'} spacing={1}>
          <IconButton aria-label="delete" onClick={onClose} sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <SvgIcon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z"
                  fill="white"
                />
              </svg>
            </SvgIcon>
          </IconButton>

          <Box>
            <Typography className="title">Job Application</Typography>
            <Typography className="sub-text">
              Please help us with answers to few questions to complete your application
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box className="drawer-content">
        <Box className="chat-content-container" ref={scrollRef}>
          <Box className="chat-inner-content">
            <Stack spacing={2}>
              <Box className="chat-row message-in">
                <Stack direction="row" spacing={1}>
                  <SvgIcon className="jn-chat-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none">
                      <path
                        d="M10.4 25.8375L12.1555 26.809C12.7873 27.1737 13.5565 27.1545 14.1824 26.7999L18.396 24.3892C18.4641 24.3443 18.5504 24.2677 18.6184 24.2228L24.0025 21.098C24.6284 20.7434 25.0296 20.0484 25.0237 19.3291L25.0116 7.82422C25.0058 7.10486 24.6062 6.41089 23.9745 6.04615L16.046 1.46864L16.0144 1.45041L14.0244 0.301469C13.3926 -0.0632728 12.5919 -0.0623187 11.966 0.2923L1.9964 6.03424C1.37049 6.38886 0.969277 7.08379 0.975122 7.80315L0.966118 16.9373C0.969695 17.1499 0.991509 17.331 1.04491 17.5303L1.05041 19.3445C1.05625 20.0638 1.45581 20.7578 2.08756 21.1226L9.98445 25.6818C10.129 25.7232 10.2554 25.7961 10.4 25.8375Z"
                        fill="#3D1C9B"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.09204 14.572L6.79785 16.1014C7.38612 16.7838 8.30381 17.1602 9.43328 17.1367C11.3157 17.1367 12.3511 16.1955 12.3511 14.2426V9.88965H9.99802V11.7014V14.4073C9.99802 14.972 9.71565 15.2779 9.22151 15.2779C8.82149 15.2779 8.46853 15.0426 8.09204 14.572ZM19.1283 17.0209V13.8679C19.1283 12.1267 18.14 11.3738 16.8693 11.3738C16.2105 11.3738 15.6457 11.5856 15.1987 12.0326V11.4914H13.0338V16.9973H15.3163V14.4091C15.3163 13.5856 15.6928 13.3032 16.1634 13.3032C16.587 13.3032 16.8458 13.562 16.8458 14.2444V17.0209H19.1283Z"
                        fill="white"
                      />
                    </svg>
                  </SvgIcon>
                  <Box className="message-in-text-container">
                    <Typography className="msg-text">Which resume would you like to apply with?</Typography>
                  </Box>
                </Stack>
              </Box>
              <Box className="chat-row message-in">
                {resumeData?.length > 0 && (
                  <ChatResumeOptions onSet={handleSelect} resumeData={resumeData} tab={tab} job={data} answers={answers} />
                )}
              </Box>
              {resumeSelected && (
                <Box className="chat-row message-out">
                  <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
                    <Box className="message-out-text-container">
                      <Stack spacing={1} sx={{ flex: '0 0 100%' }}>
                        <Box className="selected-resume-wrapper">
                          <Box className="resume-img">
                            <img alt="resume" src={resumeImg} />
                          </Box>
                          <Button
                            className="change-resume-btn"
                            variant="outlined"
                            size="large"
                            onClick={handleChangeResume}
                          >
                            Change Resume
                          </Button>
                        </Box>
                        <Typography className="msg-text">{resumeSelected?.title}</Typography>
                      </Stack>
                    </Box>
                  </Stack>
                  {questions && questions?.length > 0 && (
                    <Stack spacing={2} mt={2}>
                      <Box className="chat-row message-in">
                        <Stack direction="row" spacing={1}>
                          <SvgIcon className="jn-chat-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="28"
                              viewBox="0 0 26 28"
                              fill="none"
                            >
                              <path
                                d="M10.4 25.8375L12.1555 26.809C12.7873 27.1737 13.5565 27.1545 14.1824 26.7999L18.396 24.3892C18.4641 24.3443 18.5504 24.2677 18.6184 24.2228L24.0025 21.098C24.6284 20.7434 25.0296 20.0484 25.0237 19.3291L25.0116 7.82422C25.0058 7.10486 24.6062 6.41089 23.9745 6.04615L16.046 1.46864L16.0144 1.45041L14.0244 0.301469C13.3926 -0.0632728 12.5919 -0.0623187 11.966 0.2923L1.9964 6.03424C1.37049 6.38886 0.969277 7.08379 0.975122 7.80315L0.966118 16.9373C0.969695 17.1499 0.991509 17.331 1.04491 17.5303L1.05041 19.3445C1.05625 20.0638 1.45581 20.7578 2.08756 21.1226L9.98445 25.6818C10.129 25.7232 10.2554 25.7961 10.4 25.8375Z"
                                fill="#3D1C9B"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.09204 14.572L6.79785 16.1014C7.38612 16.7838 8.30381 17.1602 9.43328 17.1367C11.3157 17.1367 12.3511 16.1955 12.3511 14.2426V9.88965H9.99802V11.7014V14.4073C9.99802 14.972 9.71565 15.2779 9.22151 15.2779C8.82149 15.2779 8.46853 15.0426 8.09204 14.572ZM19.1283 17.0209V13.8679C19.1283 12.1267 18.14 11.3738 16.8693 11.3738C16.2105 11.3738 15.6457 11.5856 15.1987 12.0326V11.4914H13.0338V16.9973H15.3163V14.4091C15.3163 13.5856 15.6928 13.3032 16.1634 13.3032C16.587 13.3032 16.8458 13.562 16.8458 14.2444V17.0209H19.1283Z"
                                fill="white"
                              />
                            </svg>
                          </SvgIcon>
                          <Box className="message-in-text-container">
                            <Typography className="msg-text">
                              Great! Please help us with a few answers to complete the application
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  )}
                </Box>
              )}

              {answers && Object.keys(answers).length > 0 && (
                <Box>
                  <Stack spacing={2}>
                    {Object.entries(answers).map(([key, value], index) => (
                      <Box key={index}>
                        <Stack spacing={2}>
                          <Box className="chat-row message-in">
                            <Stack direction="row" spacing={1}>
                              <SvgIcon className="jn-chat-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="28"
                                  viewBox="0 0 26 28"
                                  fill="none"
                                >
                                  <path
                                    d="M10.4 25.8375L12.1555 26.809C12.7873 27.1737 13.5565 27.1545 14.1824 26.7999L18.396 24.3892C18.4641 24.3443 18.5504 24.2677 18.6184 24.2228L24.0025 21.098C24.6284 20.7434 25.0296 20.0484 25.0237 19.3291L25.0116 7.82422C25.0058 7.10486 24.6062 6.41089 23.9745 6.04615L16.046 1.46864L16.0144 1.45041L14.0244 0.301469C13.3926 -0.0632728 12.5919 -0.0623187 11.966 0.2923L1.9964 6.03424C1.37049 6.38886 0.969277 7.08379 0.975122 7.80315L0.966118 16.9373C0.969695 17.1499 0.991509 17.331 1.04491 17.5303L1.05041 19.3445C1.05625 20.0638 1.45581 20.7578 2.08756 21.1226L9.98445 25.6818C10.129 25.7232 10.2554 25.7961 10.4 25.8375Z"
                                    fill="#3D1C9B"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.09204 14.572L6.79785 16.1014C7.38612 16.7838 8.30381 17.1602 9.43328 17.1367C11.3157 17.1367 12.3511 16.1955 12.3511 14.2426V9.88965H9.99802V11.7014V14.4073C9.99802 14.972 9.71565 15.2779 9.22151 15.2779C8.82149 15.2779 8.46853 15.0426 8.09204 14.572ZM19.1283 17.0209V13.8679C19.1283 12.1267 18.14 11.3738 16.8693 11.3738C16.2105 11.3738 15.6457 11.5856 15.1987 12.0326V11.4914H13.0338V16.9973H15.3163V14.4091C15.3163 13.5856 15.6928 13.3032 16.1634 13.3032C16.587 13.3032 16.8458 13.562 16.8458 14.2444V17.0209H19.1283Z"
                                    fill="white"
                                  />
                                </svg>
                              </SvgIcon>
                              <Box className="message-in-text-container">
                                <Typography className="msg-text">{key}</Typography>
                              </Box>
                            </Stack>
                          </Box>
                          <Box className="chat-row message-out">
                            <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
                              <Box className="edit-ans">
                                <Box className="message-out-text-container">
                                  <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
                                    <Typography className="msg-text">{value}</Typography>
                                  </Stack>
                                  <IconButton
                                    aria-label="edit"
                                    className="edit-button"
                                    style={{ marginLeft: 'auto' }}
                                    onClick={() => handleUpdateAnswer(index, value)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M12.2872 16.0003H2.09789C0.941117 16.0003 0 15.0593 0 13.9024V3.71313C0 2.55635 0.941002 1.61523 2.09789 1.61523H6.76704C7.0982 1.61523 7.36641 1.88345 7.36641 2.2146C7.36641 2.54576 7.0982 2.81398 6.76704 2.81398H2.09789C1.60192 2.81398 1.19884 3.21706 1.19884 3.71303V13.9023C1.19884 14.3983 1.60192 14.8014 2.09789 14.8014H12.2872C12.7832 14.8014 13.1862 14.3983 13.1862 13.9023V9.23319C13.1862 8.90203 13.4545 8.63381 13.7856 8.63381C14.1168 8.63381 14.385 8.90203 14.385 9.23319V13.9023C14.385 15.0591 13.4441 16.0003 12.2872 16.0003Z"
                                        fill="#1F9CD7"
                                      />
                                      <path
                                        d="M4.44141 9.00838L10.9986 2.45117L13.5451 4.99768L6.98792 11.5549L4.44141 9.00838Z"
                                        fill="#1F9CD7"
                                      />
                                      <path
                                        d="M15.7098 1.43961L14.5591 0.28882C14.174 -0.0962733 13.5491 -0.0962733 13.1633 0.28882L11.8477 1.60444L14.3942 4.15095L15.7098 2.83533C16.0949 2.45024 16.0949 1.82545 15.7098 1.43961Z"
                                        fill="#1F9CD7"
                                      />
                                      <path
                                        d="M3.67143 9.9353L3.07881 12.523C3.02487 12.7598 3.23615 12.9711 3.4729 12.9171L6.06065 12.3245L3.67143 9.9353Z"
                                        fill="#1F9CD7"
                                      />
                                    </svg>
                                  </IconButton>
                                </Box>
                              </Box>
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              )}

              {showQuestions && (
                <Box className="chat-row message-in">
                  <Stack direction="row" spacing={1}>
                    <SvgIcon className="jn-chat-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none">
                        <path
                          d="M10.4 25.8375L12.1555 26.809C12.7873 27.1737 13.5565 27.1545 14.1824 26.7999L18.396 24.3892C18.4641 24.3443 18.5504 24.2677 18.6184 24.2228L24.0025 21.098C24.6284 20.7434 25.0296 20.0484 25.0237 19.3291L25.0116 7.82422C25.0058 7.10486 24.6062 6.41089 23.9745 6.04615L16.046 1.46864L16.0144 1.45041L14.0244 0.301469C13.3926 -0.0632728 12.5919 -0.0623187 11.966 0.2923L1.9964 6.03424C1.37049 6.38886 0.969277 7.08379 0.975122 7.80315L0.966118 16.9373C0.969695 17.1499 0.991509 17.331 1.04491 17.5303L1.05041 19.3445C1.05625 20.0638 1.45581 20.7578 2.08756 21.1226L9.98445 25.6818C10.129 25.7232 10.2554 25.7961 10.4 25.8375Z"
                          fill="#3D1C9B"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.09204 14.572L6.79785 16.1014C7.38612 16.7838 8.30381 17.1602 9.43328 17.1367C11.3157 17.1367 12.3511 16.1955 12.3511 14.2426V9.88965H9.99802V11.7014V14.4073C9.99802 14.972 9.71565 15.2779 9.22151 15.2779C8.82149 15.2779 8.46853 15.0426 8.09204 14.572ZM19.1283 17.0209V13.8679C19.1283 12.1267 18.14 11.3738 16.8693 11.3738C16.2105 11.3738 15.6457 11.5856 15.1987 12.0326V11.4914H13.0338V16.9973H15.3163V14.4091C15.3163 13.5856 15.6928 13.3032 16.1634 13.3032C16.587 13.3032 16.8458 13.562 16.8458 14.2444V17.0209H19.1283Z"
                          fill="white"
                        />
                      </svg>
                    </SvgIcon>
                    <Box className="message-in-text-container">
                      <Typography className="msg-text">{questions[currentQuestionIndex]}</Typography>
                    </Box>
                  </Stack>
                </Box>
              )}
              {chatCompleted && (
                <Box>
                  <Stack spacing={2} mb={2}>
                    <Box className="chat-row message-in">
                      <Stack direction="row" spacing={1}>
                        <SvgIcon className="jn-chat-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="28"
                            viewBox="0 0 26 28"
                            fill="none"
                          >
                            <path
                              d="M10.4 25.8375L12.1555 26.809C12.7873 27.1737 13.5565 27.1545 14.1824 26.7999L18.396 24.3892C18.4641 24.3443 18.5504 24.2677 18.6184 24.2228L24.0025 21.098C24.6284 20.7434 25.0296 20.0484 25.0237 19.3291L25.0116 7.82422C25.0058 7.10486 24.6062 6.41089 23.9745 6.04615L16.046 1.46864L16.0144 1.45041L14.0244 0.301469C13.3926 -0.0632728 12.5919 -0.0623187 11.966 0.2923L1.9964 6.03424C1.37049 6.38886 0.969277 7.08379 0.975122 7.80315L0.966118 16.9373C0.969695 17.1499 0.991509 17.331 1.04491 17.5303L1.05041 19.3445C1.05625 20.0638 1.45581 20.7578 2.08756 21.1226L9.98445 25.6818C10.129 25.7232 10.2554 25.7961 10.4 25.8375Z"
                              fill="#3D1C9B"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.09204 14.572L6.79785 16.1014C7.38612 16.7838 8.30381 17.1602 9.43328 17.1367C11.3157 17.1367 12.3511 16.1955 12.3511 14.2426V9.88965H9.99802V11.7014V14.4073C9.99802 14.972 9.71565 15.2779 9.22151 15.2779C8.82149 15.2779 8.46853 15.0426 8.09204 14.572ZM19.1283 17.0209V13.8679C19.1283 12.1267 18.14 11.3738 16.8693 11.3738C16.2105 11.3738 15.6457 11.5856 15.1987 12.0326V11.4914H13.0338V16.9973H15.3163V14.4091C15.3163 13.5856 15.6928 13.3032 16.1634 13.3032C16.587 13.3032 16.8458 13.562 16.8458 14.2444V17.0209H19.1283Z"
                              fill="white"
                            />
                          </svg>
                        </SvgIcon>
                        <Box className="message-in-text-container">
                          <Stack spacing={1} sx={{ flex: '0 0 100%' }}>
                            <Typography className="msg-text">
                              Great! We’ve received your responses. Click submit to send your application.{' '}
                            </Typography>
                            <Typography className="msg-text">
                              You can review and update your responses by clicking them before submitting the
                              application.
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                  <Button
                    className="chat-bot-submit-app-btn"
                    variant="contained"
                    size="large"
                    onClick={onSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit Application'}
                  </Button>
                </Box>
              )}
            </Stack>
            {loading && <Box className="chat-loader"></Box>}
          </Box>
        </Box>

        {showTextField && (
          <Box className="chat-footer">
            <Box className="chat-footer-cta">
              <Stack direction="row" alignItems={'center'} spacing={2}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    placeholder="Your Answer"
                    variant="outlined"
                    type="text"
                    multiline={true}
                    value={currentAnswer}
                    onChange={(e) => handleInputChange(e)}
                  />
                </FormControl>
                <IconButton
                  aria-label="delete"
                  disabled={!resumeSelected}
                  onClick={handleSetAnswer}
                  className="send-msg-btn"
                >
                  <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M8.95019 4.07992L15.4702 10.5999C16.2402 11.3699 16.2402 12.6299 15.4702 13.3999L8.9502 19.9199"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SvgIcon>
                </IconButton>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="select-resumes-dialog"
      >
        <SelectResumeOptions onSelect={handleSelected} resumeData={resumeData} tab={tab} job={data} selectedResume={resumeSelected} index={sliderIndex} answers={answers} chosenResume={resumeSelected} />
      </Dialog>
    </Box>
  );
};

export default ChatBotDrawer;
