import { Button, Box, Container, Paper, Stack, Table, TableBody, TableCell, Grid, CircularProgress, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCandidateApplications } from 'api/admin';
import { SplashScreen } from 'components/loading-screen';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';

const CandidateApplications = () => {
    const navigate = useNavigate();
    const [candidateApplications, setCandidateApplications] = useState([]);
    const [candidateDetail, setCandidateDetail] = useState({})
    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchCandidateApplications = async () => {
            try {
                const response = await getCandidateApplications({ id });
                if (response?.success && response.data != null) {
                    const { populatedApplications, userDetails, feedbacks } = response.data;
                    if (populatedApplications != null && userDetails != null) {
                        setCandidateApplications(populatedApplications);
                        setCandidateDetail(userDetails);
                        setFeedbacks(feedbacks);
                    }
                }
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
            setLoading(false);
        };
        fetchCandidateApplications();
    }, []);

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress />
            </Grid>
        );
    }

    const presentWorkHistory = candidateDetail?.candidate?.work_history?.filter(history => history.present) || [];
    const highestDesignations = presentWorkHistory?.map(designation => designation?.highest_designation).join(', ');

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleRowClick = (candidateId) => {
        handleNavigation(``);
    };
    return (
        <Container>
            <Box style={{ marginTop: '80px'}}>
                <Paper elevation={3} sx={{ padding: '1rem' }}>
                    <Typography variant="h6" component="div">
                        Candidate Information
                    </Typography>
                    <Box sx={{ marginTop: '2rem' }}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box>
                                <Typography variant="p" component="div" sx={{ marginBottom: '0.5rem', fontSize: '0.8rem' }}>
                                    Name: {candidateDetail?.full_name || 'N/A'}
                                </Typography>
                                <Typography variant="p" component="div" sx={{ marginBottom: '0.5rem', fontSize: '0.8rem' }}>
                                    Email: {candidateDetail?.email || 'N/A'}
                                </Typography>
                                <Typography variant="p" component="div" sx={{ marginBottom: '0.5rem', fontSize: '0.8rem' }}>
                                    Phone: {candidateDetail?.phone_number || 'N/A'}
                                </Typography>
                                <Typography variant="p" component="div" sx={{ marginBottom: '0.5rem', fontSize: '0.8rem' }}>
                                    Current Designation: {candidateDetail?.candidate?.work_history?.map((designation) => designation?.highest_designation).join(', ') || 'N/A'}
                                </Typography>
                                <Typography variant="p" component="div" sx={{ marginBottom: '0.5rem', fontSize: '0.8rem' }}>
                                    Qualification: {candidateDetail?.candidate?.educations?.map((education) => education?.degree_received).join(', ') || 'N/A' || 'N/A'}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Paper>
            </Box>

            <Box sx={{ marginTop: '2rem' }}>
                <Paper elevation={3} sx={{ padding: '1rem' }}>
                    <Typography variant="h6" component="div">
                        Candidate Applications
                    </Typography>
                    <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Company Name</TableCell>
                                    <TableCell>Job Title</TableCell>
                                    <TableCell>Application Date</TableCell>
                                    <TableCell>Application Status</TableCell>
                                    <TableCell>Feedback Input</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {candidateApplications?.map((application, index) => (
                                    <TableRow key={application._id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{application?.company_id?.user_id?.full_name ? application?.company_id.user_id.full_name : 'N/A'}</TableCell>
                                        <TableCell>{application?.job_id?.title}</TableCell>
                                        <TableCell>{application?.applied_at ? dayjs(application?.applied_at).format('DD-MM-YYYY ') : 'N/A'}</TableCell>
                                        <TableCell>{application?.status === 'saved' ? 'not applied' : application?.status}</TableCell>
                                        <TableCell>
                                            {feedbacks ? feedbacks
                                                .filter((feedback) => feedback?.application === application?._id)
                                                .map((feedback) => (
                                                    <Typography key={feedbacks?._id} variant="body2" component="div">
                                                        {feedback?.feedback}
                                                    </Typography>
                                                )) : 'N/A'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </Container>
    );
};

export default CandidateApplications;
